import React from "react"
import "./BannerNews.scss"
import { Container } from "react-bootstrap"
import SocialShare from "../SocialShare/SocialShare"
import { format } from "date-fns"
import { Link } from "gatsby"
import { teamsURL } from "../../common/utils/urls"
import Animation from "../elements/Animation"

const BannerNews = ({ news }) => {
  const { blog_category, title, date, author } = news

  const formattedDate = format(new Date(date), "MMMM do, yyyy")

  return (
    <Animation className="banner-news-wrapper">
      <Container className="banner-news-container">
        <h4 className="category">{news?.blog_category?.name}</h4>
        <h1 className="title">{title}</h1>
        <div className="banner-content">
          <p className="date">{formattedDate}</p>
          <span>/</span>
          <p className="author">
            by <Link to={`${teamsURL + author?.slug}/`}>{author?.name}</Link>
          </p>
          <span>/</span>
          <SocialShare mediaImg={news?.tile_image?.url}>
            <p className="share-text">Share this post</p>
          </SocialShare>
        </div>
      </Container>
    </Animation>
  )
}

export default BannerNews
