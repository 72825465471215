import React, {useEffect, useState} from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/rental_valuation.json"
import { parseHTML } from "../../common/utils/utils"
import RentalValuationForm from "../forms/rental-valuation"
import Animation from "../elements/Animation"
import "./RentalValuation.scss"

const RentalValuation = ({ data }) => {
  const [render, setRender] = useState(false);
  const title = data.title.data.title;
  const content = data.content.data.content;

  useEffect(()=>{
    if(!render){
      setRender(true);
    }
  })
  return (
    <div className="rental-valuation-wrapper">
      <Container>
        <div className="rental-valuation-module">
          <div className="content-section">
            
            <h1 className="title">{parseHTML(title)}</h1>
            <p className="content">{parseHTML(content)}</p>
          </div>
          <RentalValuationForm fields={FormFields} formtagclassname="rental-valuation" />
        </div>
      </Container>
    </div>
  )
}

export default RentalValuation
