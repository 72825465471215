import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import useNews from "../../hooks/dataHooks/useNews"
import NewsCard from "../NewsCard/NewsCard"
import "./OtherNewsSlider.scss"
import Animation from "../elements/Animation"

const OtherNewsSlider = ({ newsID }) => {
  const allNews = useNews()

  const filteredNews = allNews.filter(news => news.strapi_id !== newsID)

  if (_.isEmpty(filteredNews)) return null

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <Animation className={clsx("news-slider-wrap section-p-120")}>
      <Container className="news-slider-container">
        <h2 className="title">
          May also <i>interest</i> you...
        </h2>
        <div className="slider-section">
          <Slider {...settings}>
            {filteredNews.map((news, index) => (
              <NewsCard key={index} news={news} />
            ))}
          </Slider>
        </div>
      </Container>
    </Animation>
  )
}

export default OtherNewsSlider
