import { getUser } from "@starberry/myaccount-website-utils"
import $ from "jquery/dist/jquery.min.js"
import * as qs from "query-string"
import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { postFormData } from "./api/Api"
import ButtonField from "./elements/button"
import CheckboxField from "./elements/checkbox"
import FileField from "./elements/file"
import HtmlBox from "./elements/html"
import InputField from "./elements/input"
import RadioField from "./elements/radio"
import ReCaptchaBox from "./elements/recaptcha"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
// import "./assets/styles/_index.scss"
import "../forms/styles/_index.scss"
import PostcodeField from "./elements/postcode"

const FormComponent = props => {
  const userData = getUser()
  // console.log(userData)
  const [validated, setValidated] = useState(false)
  const [startDate, setStartDate] = useState()
  const [startTime, setStartTime] = useState()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [formvalues, setFormvalues] = useState("")
  const [token, setToken] = useState("")
  const [getPostCode, setGetPostCode] = useState("")
  const [getAddress, setGetAddress] = useState("")
  const [postcode, setPostcode] = useState("")
  const [postCodeAddress, setPostCodeAddress] = useState(true)
  const [address, setAddress] = useState("")
  const [postCodeError, setPostCodeError] = useState(false)

  const [radioval, setRadioval] = useState("To Sell")
  const [selectedOption, setSelectedOption] = useState("Sales")

  const [file, setFile] = useState("") // storing the uploaded file
  // storing the recived file from backend
  const [] = useState({ name: "", path: "" })
  const [] = useState(0) // progess bar
  const myRef = React.createRef()

  const [show, setShow] = useState(false)

  const recaptchaRef = React.createRef()
  const fields = props.fields

  const selectHandelChange = (name, value) => {
    setPostcode(value)
    setPostCodeAddress(true)
  }

  const postcodeChange = suggestion => {
    setPostcode(suggestion.postcode)
    setGetPostCode(suggestion.postcode)
    var selected_address = suggestion.line_1 ? suggestion.line_1 + ", " : ""
    selected_address += suggestion.line_2 ? suggestion.line_2 + ", " : ""
    selected_address += suggestion.line_3 ? suggestion.line_3 + ", " : ""
    selected_address += suggestion.postcode ? suggestion.postcode + ", " : ""

    setAddress(`${selected_address}`)
    setGetAddress(`${selected_address}`)

    setPostCodeAddress(false)
  }

  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setRadioval(event.currentTarget.value)
  }

  const handleCheckboxChange = option => {
    if (selectedOption === option) {
      // setSelectedOption("")
    } else {
      setSelectedOption(option)
    }
  }

  const handlechange = event => {
    event.target.value = event.target.value.trimStart()
  }

  const handleselectchange = event => {
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0] // accesing file
    var d = document.getElementById("custom-file")
    //alert(d.value);
    $(".form-control-browsefile .custom-file-label").html(
      d.value.replace(/C:\\fakepath\\/, "")
    )
    setFile(fileItem) // storing file

    $(".form-control-browsefile").addClass("validated")
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token
        const page_url =
          typeof window !== "undefined" ? window.location.href : ""

        let formData = new FormData()
        if (formvalues?.files) {
          formData.append(
            "files.attachment",
            formvalues.files,
            formvalues.files.name
          ) // appending file
        }

        // const sub_name = formvalues.email?.split("@")
        if(formvalues.name){
          formvalues["name"] = formvalues.name 
          formvalues["first_name"] = formvalues.first_name ? formvalues.first_name : formvalues.name
          formvalues["last_name"] = formvalues.last_name ? formvalues.last_name : formvalues.name
        }else{
          formvalues["name"] = formvalues.first_name + " " + formvalues.last_name
          formvalues["first_name"] = formvalues.first_name
          formvalues["last_name"] = formvalues.last_name
        }   
        formvalues["email_subject_user"] = props?.email_subject_user
          ? props?.email_subject_user
          : fields[0].email_subject_user
        formvalues["email_subject_admin"] = props?.email_subject_admin
          ? props?.email_subject_admin
          : fields[0].email_subject_admin

        
        if (radioval) {
          formvalues["client_property"] = radioval
        }

        if (postcode) {
          formvalues["postcode"] = postcode
        }
        if (address) {
          formvalues["postcode_address"] = address
        }
        if (selectedOption && fields[0].formname === "Home Valuation") {
          formvalues["property_enquiry_type"] = selectedOption === "Both" ? "Sales and Lettings" : selectedOption
          if (selectedOption === "Lettings") {
            formvalues["to_email_id"] = "lettings@christopherhodgson.co.uk"
          } else if (selectedOption === "Sales"){
            formvalues["to_email_id"] = "sales@christopherhodgson.co.uk"
          }
          else {
            formvalues["to_email_id"] = "info@christopherhodgson.co.uk"
          }
        }

        formvalues["extra"] = JSON.stringify(formvalues)
        if (props?.prop_address) {
          formvalues["property_address"] = props?.prop_address ? props?.prop_address : address
        }
        else if(address) {
          formvalues["property_address"] = address          
        }
        if (props?.prop_url) {
          formvalues["property_url"] = props?.prop_url
        }
        if (props?.prop_img_url) {
          formvalues["property_img"] = props?.prop_img_url
        }
        if(props?.preferred_date){
          formvalues["preferred_date"] = props?.preferred_date
        }
        if(props?.preferred_time){
          formvalues["preferred_time"] = props?.preferred_time
        }
        if (props?.prop_img_url) {
          formvalues["property_img_url"] = props?.prop_img_url
        }
        if (props?.property_title) {
          formvalues["property_title"] = props?.property_title
        }
        if (props?.plan) {
          formvalues["plan"] = props?.plan
        }
        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          if (apiRes?.data?.form?.attachment) {
            const attachement = apiRes?.data?.form?.attachment?.length > 0
            const attachement_url = apiRes?.data?.form?.attachment[0]?.url
            if (attachement && attachement_url) {
              let cvUrl = apiRes?.data?.form?.attachment[0]?.url
              // If image from externall url, use that
              if (cvUrl.match(/(http|https):\/\//g)) {
                formvalues["file"] = `${cvUrl}`
              } else {
                // or we consider it is loading from api url
                formvalues["file"] = `${cvUrl}`
              }
            }
          }

          // lets send mail
          let url = process.env.GATSBY_CLOUD_URL + "/api/functions"
          if(fields[0].formname === "Share to Friend"){
            url = process.env.GATSBY_CLOUD_URL + "/api/shareto-friend"

          } 
          console.log('formvalues', formvalues)
          await window.fetch(url, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        })

        const url = typeof window !== "undefined" ? window.location.href : ""

        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: props?.teammembername
            ? "Team Contact - " + props?.teammembername
            : fields[0].formname === " Property Viewing"
            ? "Book Viewing"
            : fields[0].formname === "Home Valuation" 
            ? fields[0].formname + " " + selectedOption
            : fields[0].formname,
          formLabel: props?.teammembername
            ? "Team Contact - " + props?.teammembername
            : fields[0].formname === " Property Viewing"
            ? "Book Viewing"
            : fields[0].formname === "Home Valuation" 
            ? fields[0].formname + " " + selectedOption
            : fields[0].formname,
        })

        setShowerror(false)
        setThankyou(true)
        setShow(true)
        setTimeout(() => {
          const element = document.querySelector(".alert-success")
          if (element) {
            window.scrollTo({
              behavior: "smooth",
              top:
                element.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                200,
            })
          }
        }, 100)
        setTimeout(() => {
          setThankyou(false)
        }, 3000)
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    // console.log("captcha verified")
    setToken(token)
    // setToken("")
  }

  const handleSubmit = event => {
     
    const form = event.currentTarget
    // setRadioval($("input[name=enquiry]:checked").val())
    //   $("input[name=enquiry]").val(radioval)
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      setTimeout(() => {
        const element = document.querySelector(".alert-danger")
        if (element) {
          window.scrollTo({
            behavior: "smooth",
            top:
              element.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              200,
          })
        }
      }, 100)
    } else {
      event.preventDefault()
      setShowerror(false)
      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = props?.propertyTeam ? fields[0].email_temp_admin+"_property" : fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      json["files"] = file
      setFile(file)
      setFormvalues(json)

      recaptchaRef.current.execute()
      // setToken("test")
      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="form-component-wrapper">
      <div ref={myRef} />
      <Form
        className={`stb-form ${props.formtagclassname}`}
        id="contact-form"
        name={fields[0].formname}
        // action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        {showerror && (
          <div className="alert alert-danger">{fields[0].error_text}</div>
        )}

        {showthankyou && (
          <div className="alert alert-success">{fields[0].success_text}</div>
        )}
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />

        <input type="hidden" name="form_page" value={url} />
        <div className="block-form row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  defaultValue={
                    field.name === "property_address"
                      ? props?.prop_address
                      : field.name === "first_name"
                      ? userData?.name
                      : field.name === "last_name"
                      ? userData?.surname
                      : field.name === "email"
                      ? userData?.email
                      : field.name === "telephone"
                      ? userData?.mobile
                      : ""
                  }
                  startDate={field.type === "date_time" ? startDate : ""}
                  setStartDate={field.type === "date_time" ? setStartDate : ""}
                  startTime={field.type === "timePicker" ? startTime : ""}
                  setStartTime={field.type === "timePicker" ? setStartTime : ""}
                  phoneNumber={field.type === 'international_phone' ? phoneNumber : ''}
                  setPhoneNumber={field.type === 'international_phone' ? setPhoneNumber : ''}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  className={field.className}
                  step={field.step}
                  id={field.id}
                  isDisable={field.isDisable}
                />
              )
            }
            if ("postcode" === field.element) {
              return (
                <PostcodeField
                  name={field.name}
                  // divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  selectHandelChange={selectHandelChange}
                  postcodeChange={postcodeChange}
                  setPostCodeError={setPostCodeError}
                  id={field.id}
                />
              )
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                  className={field.className}
                />
              )
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.row}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  label={field.label}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  className={field.className}
                />
              )
            }
            
            if ("checkbox" === field.element && "p_type" == field.name) {
              return (
                <div className="valuation-custom-checkbox theme-form-list full-width">
                  {field?.values.map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        checked={selectedOption === option}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <div className="check-content"> {option}</div>
                    </label>
                  ))}
                </div>
              )
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  className={field.className}
                />
              )
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                  className={field.className}
                  file={file}
                />
              )
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  checked={field.checked}
                  selected={radioval}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getRadio}
                  lastchild={field.lastchild}
                  className={field.className}
                />
              )
            }

            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  className={field.className}
                  id={field.id}
                />
              )
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={recaptchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                  className={field.className}
                />
              )
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  className={field.className}
                />
              )
            }
          })}
        </div>
      </Form>
    </div>
  )
}

const DefaultForm = props => (
  <FormComponent
    fields={props.fields}
    classname={props.classname}
    to_email_id={props.to_email_id}
    property_title={props.title}
    formtagclassname={props.formtagclassname}
    prop_url={props?.prop_url}
    prop_address={props?.prop_address}
    prop_img_url={props?.prop_img_url}
    email_template_type={props?.email_template_type}
    thankyouMessage={props?.thankyouMessage}
    email_subject_user={props?.email_subject_user}
    email_subject_admin={props?.email_subject_admin}
    plan={props?.plan}
    teammembername={props?.teammembername}
    preferred_date={props?.preferred_date}
    preferred_time={props?.preferred_time}
    propertyTeam={props?.propertyTeam}

  />
)

export default DefaultForm
