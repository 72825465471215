import React, { useState, useEffect } from "react"
import { Button, Col, Row, Form } from "react-bootstrap"
// import TextField from "@components/formComponents/textField";
import { filterNumber, numberFormat, rentalYield } from "./utils"
import { eventTracking } from "../../common/utils/utils"

// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  const { title } = props

  const currency = process.env.GATSBY_SITE_CURRENCY
    ? process.env.GATSBY_SITE_CURRENCY
    : "£"
  const [validated, setValidated] = useState(true)
  const [purchasePrice, setPurchasePrice] = useState(
    numberFormat(filterNumber(props.price))
  )
  const [rent, setRent] = useState(numberFormat(1000))
  const [result, setResult] = useState(null)

  const handlePrice = event => {
    let val = event.target.value

    setPurchasePrice(numberFormat(filterNumber(val)))
    doCalculate(numberFormat(filterNumber(val)), rent)
  }

  const handleRent = event => {
    setRent(numberFormat(filterNumber(event.target.value)))
    doCalculate(purchasePrice, numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (purchasePrice, rent) => {
    let result = rentalYield(filterNumber(purchasePrice), filterNumber(rent))
    setResult(numberFormat(result))
  }

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    if (filterNumber(purchasePrice) === 0 || filterNumber(rent) === 0) {
      setValidated(false)
    } else {
      setValidated(true)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "Calculator",
        formType: title,
        formId: "Rental Yield Calculator",
        formName: "Rental Yield Calculator",
        formLabel: "Rental Yield Calculator",
      })
      if (filterNumber(purchasePrice) && filterNumber(rent)) {
        getResult(purchasePrice, rent)
      }
    }
  }

  const doCalculate = (purchasePrice, rent) => {
    if (filterNumber(purchasePrice) && filterNumber(rent)) {
      getResult(purchasePrice, rent)
    }
  }
  useEffect(() => {
    doCalculate(purchasePrice, rent)
  }, [])

  const url = typeof window !== 'undefined' ? window.location.href : '';


  return (
    <div className="buy-to-let-wrapper calculator-form">
      {!validated && (
        <div className="alert alert-danger alert-error">
          <p>* All fields are required</p>
        </div>
      )}
      <Row>
        <Col md="4" className="col-md-4 col-12">
          <Form.Group className="calc-form-group">
            <Form.Label>Property Price (£):</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={purchasePrice}
              onChange={e => {
                setResult(null)
                handlePrice(e)
              }}
              name={"purchase_price"}
              class="form-control"
            />
          </Form.Group>
          {/* <TextField
            name="purchase_price"
            type="text"
            label="Property Price (£):"
            inlineIcon="poundIcon"
            class="form-control"
            inputClassName="outline-grey-border"
            placeholder={'Purchase Price'}
            value={purchasePrice}
            onChange={(e) => {
              setResult(null)
              handlePrice(e)
            }}
          /> */}
        </Col>
        <Col md="4" className=" col-md-4 col-12">
          <Form.Group className="calc-form-group">
            <Form.Label>Monthly Rent (£):</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={rent}
              onChange={e => {
                setResult(null)
                handleRent(e)
              }}
              name={"monthly_rent"}
              class="form-control"
            />
          </Form.Group>
          {/* <TextField
            name="monthly_rent"
            type="text"
            label="Monthly Rent (£):"  
            class="form-control"
            inlineIcon="poundIcon"
            inputClassName="outline-grey-border"
            placeholder={'Monthly Rent'}
            value={rent}
            onChange={(e) => {
              setResult(null)
              handleRent(e)
            }}
          /> */}
        </Col>

        <Col
          md="4"
          className="form-action col-md-4 col-12 d-flex align-items-end "
        >
          <Button
            // variant="primary-light"
            type="submit"
            onClick={event => {
              handleSubmit(event)
              eventTracking({eventName :"Rental Yield calculator",url:url})
            }}
            className="button button-green"
          >
            Calculate Yield
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md="6" className="mb-24">
          {result && (
            <div className="estimate-card">
              <div className="property-desc-title-sm">
                Estimated rental yield
              </div>
              <div className="property-desc-calc-price">{result}%</div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default RentalyieldCalc
